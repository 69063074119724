import './ResidentsOverview.style.scss'

import {useQuery} from '@apollo/client'
import {Link, useParams, useSearchParams} from 'react-router-dom'
import Badge from '../../components/Badge'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import Column from '../../components/Grid/Column'
import Row from '../../components/Grid/Row'
import Section from '../../components/Grid/Section'
import Icon from '../../components/Icon'
import {formatMoney, toCommonDateFormat} from '../../functions'
import {
  TGetLeaseForResidentResponse,
  TLeaseForResidentVariable,
} from '../../data/graphql/queries/people/types'
import {GET_LEASE_FOR_RESIDENT} from '../../data/graphql/queries/people'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import useToast from '../../hooks/useToast'

const ResidentOverviewPanel = () => {
  const {showToast} = useToast()
  const params = useParams<{residentId: string}>()
  const [searchParams] = useSearchParams()

  const residentId = Number(params.residentId)
  const unitId = searchParams.get('unitId')
  const leaseId = searchParams.get('leaseId')

  const leaseDetails = useQuery<TGetLeaseForResidentResponse, TLeaseForResidentVariable>(
    GET_LEASE_FOR_RESIDENT,
    {
      variables: {
        personId: residentId,
        condition: {
          leaseId: leaseId || '',
          unitId: unitId || '',
          isActive: true,
          isDeleted: false,
        },
        orderBy: ['CREATED_DT_DESC'],
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Resident Lease Data',
          type: 'error',
        })
      },
    },
  )

  const leaseData = leaseDetails.data?.transactionalDb?.allLeases?.nodes?.[0]
  const residentType = leaseDetails.data?.transactionalDb?.person.residentType
  const isPositiveBalance = parseFloat(leaseData?.currentBalance || '0') >= 0

  const getPersonTitle = () => {
    if (!leaseData?.leaseHolderId) {
      return 'Unknown'
    }

    if (residentId === +leaseData.leaseHolderId) {
      return 'Head of Household'
    }

    if (residentType?.code === 'HOH') {
      return 'Co-Head of Household'
    }

    return residentType?.description
  }

  const getLeaseHolderName = () => {
    const person = leaseData?.leaseholder

    if (!person?.firstName) {
      return 'Unknown'
    }

    return `${person.firstName} ${person.lastName}`
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Section spacing={'none'}>
        <Row>
          <Column xl={'6'}>
            <Card padding='md'>
              <CardBody padding='none'>
                <div className={'d-flex space-between'}>
                  <div className={'d-flex card-info'}>
                    <Column>
                      <h4
                        className={
                          'flex-grow-1 no-margin body-small-light-12 color-black'
                        }
                      >
                        Current Balance
                      </h4>
                      <h5 className={`no-margin ${isPositiveBalance ? '' : 'past-due'}`}>
                        {formatMoney(leaseData?.currentBalance || 0)}
                      </h5>
                    </Column>
                  </div>
                  {!isPositiveBalance ? (
                    <Badge size='sm' theme='danger'>
                      Past Due
                    </Badge>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Column>

          <Column xl={'6'}>
            <Card padding='md'>
              <CardBody padding='none'>
                <div className={'d-flex space-between'}>
                  <div className={'d-flex card-info'}>
                    <Icon icon={'credit-card'} theme={'primary'} size={'md'} />
                    <Column>
                      <h4
                        className={
                          'flex-grow-1 no-margin body-small-light-12 color-black'
                        }
                      >
                        Next Bill Due On
                      </h4>
                      <h5 className={'no-margin'}>
                        {formatMoney(leaseData?.rentAmount || 0)}
                      </h5>
                      <div className={'no-margin desktop-label-semibold-14'}>
                        Due: {toCommonDateFormat(leaseData?.paymentDueDate) || '—'}
                      </div>
                    </Column>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Column>

          <Column xl={'6'}>
            <Card padding='md'>
              <CardBody padding='none'>
                <div className={'d-flex space-between flex-column card-info'}>
                  <div className={'d-flex flex-column card-info'}>
                    <div className='d-flex align-center '>
                      <Icon icon='user' theme='primary' size={'md'} />
                      <h6 className='no-margin'>Personal Information</h6>
                    </div>
                    <Card padding='sm' theme='gray'>
                      <CardBody padding='sm'>
                        <div className='d-flex personal-info-title align-center body-small-light-12'>
                          <p>Title</p> <h6 className='no-margin'>{getPersonTitle()}</h6>
                        </div>
                        <hr />
                        <div className='d-flex space-between'>
                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Date of Birth</div>
                            <div className='body-small-light-12'>—</div>
                          </div>
                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Employment</div>
                            <div className='body-small-light-12'>—</div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Column>

          <Column xl={'6'}>
            <Card padding='md'>
              <CardBody padding='none'>
                <div className={'d-flex space-between flex-column card-info'}>
                  <div className={'d-flex flex-column card-info'}>
                    <div className='d-flex align-center '>
                      <Icon icon='home' theme='primary' size={'md'} />
                      <h6 className='no-margin'>
                        Lease Details ({leaseData?.type.description})
                      </h6>
                    </div>
                    <Card padding='sm' theme='gray'>
                      <CardBody padding='sm'>
                        <div className='d-flex personal-info-title align-center body-small-light-12'>
                          <p>Floor Plan</p>{' '}
                          <h6 className='no-margin'>
                            {leaseData?.unit?.floorPlan?.name || '—'}
                            &nbsp;
                            {leaseData?.unit?.floorPlan?.description
                              ? `(${leaseData.unit?.floorPlan.description})`
                              : null}
                          </h6>
                        </div>
                        <hr />
                        <div className='d-flex space-between'>
                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Lease Holder</div>
                            <div className='body-small-light-12'>
                              {leaseData?.leaseholder &&
                              +leaseData.leaseHolderId !== residentId ? (
                                <Link
                                  to={`/people/residents/${leaseData.leaseHolderId}/overview?unitId=${unitId}&leaseId=${leaseId}`}
                                  className={'leaseholder-link'}
                                >
                                  {getLeaseHolderName()}
                                </Link>
                              ) : (
                                getLeaseHolderName()
                              )}
                            </div>
                          </div>

                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Rent</div>
                            <div className='body-small-light-12'>
                              {formatMoney(leaseData?.rentAmount || 0)}
                            </div>
                          </div>

                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Lease Start</div>
                            <div className='body-small-light-12'>
                              {toCommonDateFormat(leaseData?.leaseBeginDate) || '—'}
                            </div>
                          </div>
                          <div className='d-flex flex-column'>
                            <div className='label-small-regular-12'>Lease Ends</div>
                            <div className='body-small-light-12'>
                              {toCommonDateFormat(leaseData?.leaseEndDate) || '—'}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Column>
        </Row>
      </Section>
    </ErrorBoundary>
  )
}

export default ResidentOverviewPanel
